<template>
  <div class='px-2 py-2 relative'>
    <div class='flex flex-row justify-between px-2 py-2 font-semibold'>
      <div class='flex flex-row'>
        <h1 class='px-2 py-2'>{{ title }}</h1>
        <button
          class='flex-shrink-0 py-2 pl-2 pr-3 mx-2 bg-gray-100 border border-gray-300 hover:border-blue-300 hover:bg-gray-50 hover:shadow-md hover:text-gray-900 flex flex-row justify-start items-center rounded-md text-sm gap-x-2 uppercase'
          @click='addPiDiv'>
          <plus-circle-icon class='inline-block h-5' />
          추가
        </button>
        <button
          class='flex-shrink-0 py-2 pl-2 pr-3 mx-2 bg-gray-100 border border-gray-300 hover:border-blue-300 hover:bg-gray-50 hover:shadow-md hover:text-gray-900 flex flex-row justify-start items-center rounded-md text-sm gap-x-2 uppercase'
          :disabled='filteredNewData.length === 0'
          @click='confirmAddAll'>
          <save-icon class='inline-block h-5' />
          일괄저장
        </button>
      </div>
      <button
        class='justify-end rounded-md px-4 py-2 text-white text-xs font-semibold'
        style='background-color: #223645;'
        @click='confirmCalcuate'>
        포트폴리오 재산출
      </button>
    </div>
    <div class='overflow-auto' style='height: calc(100% - 4rem);'>
      <table class='text-xs items-stretch'>
        <thead>
          <tr class='border-b whitespace-no-wrap text-left'>
            <th class='px-2 py-2 sticky top-0 z-10'>날짜</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'>배당금</th>
            <th class='px-2 py-2 text-right sticky top-0 z-10'></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='div in existsData' :key='`${div.id}-${div.index}`'
            class='border-b border-gray-100 hover:bg-blue-100 cursor-pointer'>
            <td class='px-2 py-2'>
              <el-date-picker
                v-model='div.createdOn'
                value-format="yyyy-MM-dd"
                type='date'
                size='small'
                style='width: 8rem;'
                placeholder='Pick a day'
                :clearable='false'
                @change='updateExistsData(div)'>
              </el-date-picker>
            </td>
            <td class='px-2 py-2 text-right tabular-number'>
              <vue-numeric
                v-model='div.dividend'
                output-type='number'
                class='form-input text-xs text-right'
                style='width: 8rem; font-size: 0.7rem;'
                :minus='true'
                separator=','
                @change='updateExistsData(div)'>
              </vue-numeric>
            </td>
            <td class='px-2 py-2'>
              <button
                class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
                @click='confirmDelete(div)'>
                삭제
              </button>
            </td>
            <td class='px-2 py-2'>
              <button
                v-if='div.id === 0'
                :disabled='validData(div)'
                class='block border pl-3 pr-4 py-2 rounded-md text-xs lg:text-sm text-gray-700 border-gray-300 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
                @click='confirmAdd(div)'>
                저장
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PlusCircleIcon, SaveIcon } from '@vue-hero-icons/outline'
import { cloneDeep } from 'lodash'

export default {
  name: 'PiDivTable',
  components: {
    PlusCircleIcon,
    SaveIcon,
  },
  props: [
    'itemName',
    'shareCode',
  ],
  data () {
    return {
      existsData: [],
      isUpdate: true,
    }
  },
  watch: {
    itemName: {
      handler: function () {
        this.existsData = cloneDeep(this.tmsPiDivs.filter(data => data.name === this.itemName))
      },
      immediate: true,
    },
    isUpdate: {
      handler: function (newVal) {
        if (newVal) {
          this.existsData = cloneDeep(this.tmsPiDivs.filter(data => data.name === this.itemName))
          this.isUpdate = false
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('piPortfolios', [
      'tmsPiDivs',
      'emptyDiv',
    ]),
    title () {
      return `${this.itemName} (${this.shareCode})`
    },
    filteredNewData () {
      return this.existsData.filter(data => data.id === 0)
    },
  },
  methods: {
    ...mapActions('piPortfolios', [
      'createDividend',
      'updateDividend',
      'deleteDividend',
      'calculatePiPortfolio',
    ]),
    confirmCalcuate () {
      this.$confirm(`포트폴리오 재산출 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.calculatePiPortfolio().then(() => {
          this.$message({
            type: 'success',
            message: '재산출 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '취소'
        })
      })
    },
    validData (div) {
      if (div.createdOn && div.dividend > 0) {
        return false
      } else {
        return true
      }
    },
    confirmAdd (div) {
      this.$confirm(`저장 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.createDividend(div).then(() => {
          this.isUpdate = true
          this.$message({
            type: 'success',
            message: '저장 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '저장 취소'
        })
      })
    },
    confirmAddAll () {
      this.$confirm(`일괄저장 하시겠습니까??`, 'Warning', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        let promiseArray = []
        for (let i=0; i < this.filteredNewData.length; i++) {
          promiseArray.push(this.createDividend(this.filteredNewData[i]))
        }
        Promise.all(promiseArray).then(() => {
          this.isUpdate = true
          this.$message({
            type: 'success',
            message: '저장 완료'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '저장 취소'
        })
      })
    },
    confirmDelete (div) {
      if (div.id === 0) {
        let index = this.existsData.findIndex(newDiv => newDiv.index === div.index)
        if (index >= 0) {
          this.existsData.splice(index, 1)
        }
      } else {
        this.$confirm(`삭제 하시겠습니까??`, 'Warning', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning'
        }).then(() => {
          this.deleteDividend(div.id).then(() => {
            this.isUpdate = true
            this.$message({
              type: 'success',
              message: '삭제 완료'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '삭제 취소'
          })
        })
      }
    },
    addPiDiv () {
      let newDiv = cloneDeep(this.emptyDiv)
      newDiv.name = this.itemName
      newDiv.shareCode = this.shareCode
      newDiv.index = this.existsData.length + 1
      this.existsData.push(newDiv)
    },
    updateExistsData (div) {
      if (div.id > 0) {
        this.updateDividend(div).then(() =>
          this.isUpdate = true
        )
      }
    },
  }
}
</script>

<style lang='scss' scoped>
  @media (min-width: 1024px) {
    .content-container {
      height: calc(100vh - 6rem);
    }
  }
</style>
